<template>
  <UModal class="max-w-xs md:max-w-md">
    <div class="absolute right-2 top-2 flex justify-end">
      <UIcon
        name="i-mdi-close"
        class="m-4 size-8 cursor-pointer rounded-full p-1 text-super-dark-grey hover:bg-trublue-100"
        @click="ignoreRelease"
      />
    </div>

    <div class="-mt-4 mb-6 flex flex-col items-center justify-center gap-4 text-center">
      <NuxtImg
        src="/images/truhub_login.svg"
        class="w-72 select-none"
        alt="Picture of a magical system"
      />

      <h2 class="text-lg font-semibold">
        {{ $t('releases.modal.title') }}
      </h2>
      <p class="text-sm text-grey-blue">
        {{ $t('releases.modal.subtitle') }}
      </p>
      <HubButton
        :label="$t('releases.modal.okButton')"
        @click="goToReleasePage"
      />
      <HubButton
        :label="$t('releases.modal.noButton')"
        type="ghost"
        @click="ignoreRelease"
      />
    </div>
  </UModal>
</template>

<script setup lang="ts">
const { t } = useI18n()

const userStore = useUserStore()
const releaseStore = useReleaseStore()
const { currentUser } = storeToRefs(userStore)
const { latestRelease } = storeToRefs(releaseStore)

async function goToReleasePage() {
  navigateTo('/releases')

  if (!currentUser.value || !currentUser.value.id || !latestRelease.value) {
    useHubToast({
      type: 'danger',
      message: t('releases.error')
    })
    return
  }

  await $hubFetch('/api/v4/users/current/preferences', {
    method: 'put',
    body: {
      userId: currentUser.value.id,
      value: latestRelease.value.releaseNumber,
      name: 'LastAcknowledgedRelease',
      type: 'string'
    }
  })

  await userStore.fetchCurrentUserPreferences()
}

async function ignoreRelease() {
  if (!currentUser.value || !currentUser.value.id || !latestRelease.value) {
    useHubToast({
      type: 'danger',
      message: t('releases.error')
    })
    return
  }

  const setIgnoredRelease = () => $hubFetch('/api/v4/users/current/preferences', {
    method: 'put',
    body: {
      // @ts-expect-error this variable will always be defined
      userId: currentUser.value.id,
      // @ts-expect-error this variable will always be defined
      value: latestRelease.value.releaseNumber,
      name: 'LastIgnoredRelease',
      type: 'string'
    }
  })
  const ignoredReleaseTime = () => $hubFetch('/api/v4/users/current/preferences', {
    method: 'put',
    body: {
      // @ts-expect-error this variable will always be defined
      userId: currentUser.value.id,
      value: new Date().toISOString(),
      name: 'LastIgnoredReleaseTime',
      type: 'string'
    }
  })

  await Promise.all([setIgnoredRelease(), ignoredReleaseTime()])

  await userStore.fetchCurrentUserPreferences()
}
</script>
